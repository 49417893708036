@use 'setting' as *;

/* ======================================================================
 p_404
====================================================================== */

.p_404 {
  .contents_block {
    background: $color_21;
    padding: 60px 220px 100px;
    @include sp {
      padding: 40px 20px 60px;
    }
    .reason {
      background: $color_23;
      border-radius: 4px;
      color: $color_4;
      max-width: 810px;
      @include sp {
        padding: 15px;
        margin: 20px 0;
      }
      @include pc {
        padding: 30px 40px;
        margin: 30px auto;
      }
    }
    .btn_wrap {
      margin: 40px auto 0;
      .c_btn {
        @include pc {
          min-width: 335px;
          line-height: 27px;
        }
      }
    }
  }
}
